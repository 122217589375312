/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from "react";
import SignaturePad from "react-signature-canvas";
import { Button } from "../button";

interface SignatureCanvasProps {
    text: string;
}

export const SignatureCanvas: React.FunctionComponent<SignatureCanvasProps> = ({
    text,
}: SignatureCanvasProps) => {
    const signCanvas = useRef() as React.MutableRefObject<any>;
    const clear = () => signCanvas.current.clear();
    const ma = "rgba(255,255,255,1)";
    const ngahere = "rgba(0,130,72,1)";

    return (
        <>
            <p>{text}</p>
            <SignaturePad
                backgroundColor={ma}
                ref={signCanvas}
                penColor={ngahere}
                canvasProps={{
                    className: "signatureCanvas signature-pad",
                }}
            />
            <div className="signature-button">
                <Button
                    data-testid="btn-clear-sign"
                    variant="tertiary"
                    onClick={clear}>
                    Clear
                </Button>
            </div>
        </>
    );
};
