import { useScript } from "@plinknz/tah-website-elements";
import * as React from "react";

interface AppWindow extends Window {
    AddressFinder: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Widget: any;
    };
}

interface Global extends NodeJS.Global {
    window: AppWindow;
}

const appGlobal = (global as unknown) as Global;

export const { window } = appGlobal;

const AddressFinderWidgetSrc =
    "https://api.addressfinder.io/assets/v3/widget.js";

export interface AddressMeta {
    id: string;
    address_line_1: string;
    address_line_2: string;
    locality_name: string;
    state_territory: string;
    city: string;
    selected_state: string;
    postcode: string;
    suburb: string;
    selected_suburb: string;
    selected_city: string;
    country: string;
    full_address: string;
    canonical_address: string; // official address recorded by aus post
}

export interface Address {
    line1: string;
    line2: string;
    suburb: string;
    city: string;
    postcode: string;
    country: string;
}

export interface AddressFinderInputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    id: string;
    onSelected: (fullAddress: string, address: Address | AddressMeta) => void;
    addressFinderKey: string;
    country?: "AU" | "NZ";
    container?: HTMLElement;
    inputClassName?: string;
    listClassName?: string;
    itemClassName?: string;
    hoverClassName?: string;
    raw?: boolean;
}

export const AddressFinderInput = ({
    id,
    country = "NZ",
    container,
    onSelected,
    inputClassName,
    listClassName = "address-autocomplete__suggestions",
    itemClassName = "address-autocomplete__suggestions__item",
    hoverClassName = "address-autocomplete__suggestions__item--active",
    addressFinderKey,
    ...props
}: AddressFinderInputProps) => {
    const mounted = React.useRef<HTMLInputElement | null>(null);
    const [scriptLoaded] = useScript(mounted, AddressFinderWidgetSrc);

    React.useEffect(() => {
        if (scriptLoaded && mounted.current) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const widget = new window.AddressFinder.Widget(
                document.getElementById(id),
                addressFinderKey,
                country,
                {
                    list_class: listClassName,
                    item_class: itemClassName,
                    hover_class: hoverClassName,
                    manual_style: true,
                    container:
                        container ||
                        document.getElementById(`address-container-${id}`),
                    address_params: {},
                    max_results: 5,
                }
            );

            widget.on(
                "address:select",
                (fullAddress: string, metaData: AddressMeta) => {
                    const addressData: Address | AddressMeta = {
                        ...metaData,
                        country,
                    };

                    onSelected(fullAddress, addressData);
                }
            );
        }
    }, [id, country, scriptLoaded, mounted.current]);

    return (
        <>
            <input
                ref={mounted}
                aria-autocomplete="list"
                aria-haspopup
                aria-owns={container ? container.id : `address-container-${id}`}
                type="text"
                autoComplete="off"
                className={inputClassName}
                id={id}
                {...props}
            />
            {!container && (
                <div
                    id={`address-container-${id}`}
                    className="address-autocomplete"
                />
            )}
        </>
    );
};
