import * as React from "react";
import { generate } from "shortid";

export interface DropdownProps {
    label?: string;
    options?: string[];
    onChange?: (value: string) => void;
    name?: string;
    selected?: string;
}

export const Dropdown: React.FunctionComponent<DropdownProps> = ({
    label,
    options,
    onChange,
    name,
    selected,
}: DropdownProps) => {
    return (
        <>
            <div className="form-group">
                <label data-testid={name} className="dropdown-label">
                    {label}
                </label>
                <select
                    className="dropdown"
                    data-testid={`dropdown-${name}`}
                    name={name || "select"}
                    value={selected}
                    onChange={(value: React.ChangeEvent<HTMLSelectElement>) => {
                        onChange(value.currentTarget.value);
                    }}>
                    {options?.map((option) => (
                        <option
                            data-testid={option}
                            value={option}
                            key={generate()}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
};
