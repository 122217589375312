/* eslint-disable react/destructuring-assignment,no-param-reassign,@typescript-eslint/no-unsafe-assignment */
import * as React from "react";
import * as SurveyTypes from "survey-react";
import { AddressFinderInput } from "../form/address-finder-input";

export const ADDRESS_FINDER_KEY = "addressfinder";

export const initializeAddressFinderWidget = async (
    survey: typeof SurveyTypes
) => {
    const config = {
        name: ADDRESS_FINDER_KEY,
        title: "Address Finder",
        isFit: (question: SurveyTypes.QuestionTextModel) =>
            question.getType() === ADDRESS_FINDER_KEY,
        activatedByChanged: () => {
            survey.JsonObject.metaData.addClass(
                ADDRESS_FINDER_KEY,
                [],
                null,
                "text"
            );
        },
        // eslint-disable-next-line react/display-name
        render: (question: SurveyTypes.QuestionTextModel) => (
            <>
                <div className="form-row">
                    <div className="form-group">
                        <AddressFinderInput
                            id="address-finder-input"
                            className="input"
                            placeholder="Start typing your address here..."
                            addressFinderKey={process.env.ADDRESS_FINDER_KEY}
                            country="NZ"
                            onSelected={(_fullAddress, newAddress) => {
                                question.value = newAddress;
                            }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <input
                            className="input"
                            id="line1"
                            type="text"
                            placeholder="Address Line 1"
                            value={question?.value?.address_line_1 || ""}
                            onChange={(event) => {
                                question.value = {
                                    ...question.value,
                                    address_line_1: event.currentTarget.value,
                                };
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            className="input"
                            id="line2"
                            type="text"
                            placeholder="Address Line 2"
                            value={question?.value?.address_line_2 || ""}
                            onChange={(event) => {
                                question.value = {
                                    ...question.value,
                                    address_line_2: event.currentTarget.value,
                                };
                            }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <input
                            id="suburb"
                            className="input"
                            type="text"
                            placeholder="Suburb"
                            value={question?.value?.suburb || ""}
                            onChange={(event) => {
                                question.value = {
                                    ...question.value,
                                    suburb: event.currentTarget.value,
                                };
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            id="city"
                            className="input"
                            type="text"
                            placeholder="City"
                            value={question?.value?.city || ""}
                            onChange={(event) => {
                                question.value = {
                                    ...question.value,
                                    city: event.currentTarget.value,
                                };
                            }}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <input
                            id="postcode"
                            type="text"
                            className="input"
                            placeholder="Postcode"
                            value={question?.value?.postcode || ""}
                            onChange={(event) => {
                                question.value = {
                                    ...question.value,
                                    postcode: event.currentTarget.value,
                                };
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            id="country"
                            className="input"
                            type="text"
                            placeholder="Country"
                            value={question?.value?.country || ""}
                            onChange={(event) => {
                                question.value = {
                                    ...question.value,
                                    country: event.currentTarget.value,
                                };
                            }}
                        />
                    </div>
                </div>
            </>
        ),
    };
    survey.CustomWidgetCollection.Instance.addCustomWidget(
        config,
        "customtype"
    );
};
