import { DownloadFileItem, getFileSize } from "@plinknz/tah-website-elements";
import * as React from "react";
import { generate } from "shortid";

export type NewsGridTypeName =
    | "ComponentContentBlocksNewsGrid"
    | "ComponentContentBlocksPanuiGrid"
    | "ComponentMemberPageGrid";

interface DownloadPanelProps {
    title?: string;
    files?: DownloadFileItem[];
}

export const DownloadPanels = ({ title, files }: DownloadPanelProps) => {
    return (
        <div className="news-grid" data-testid="news-grid">
            <div className="news-grid-wrapper || constrain-width">
                {title && <h3>{title}</h3>}
                <div className="news-grid-items">
                    {files.map((file) => (
                        <a
                            className="news-grid-item has-background"
                            key={generate()}
                            rel="noreferrer"
                            target="_blank"
                            style={{
                                backgroundImage: `url("${
                                    file.image?.url || "/images/intro-bg.jpg"
                                }")`,
                            }}
                            href={file.file.url}
                            download>
                            <h3 className="news-grid-item-title">
                                {file.name}
                                {file.file.size && (
                                    <div className="links-block-item-meta">
                                        {getFileSize(file.file.size)}
                                        <i className="links-block-item-icon fas fa-download fa-lg" />
                                    </div>
                                )}
                            </h3>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};
