import * as React from "react";
import * as SurveyType from "survey-react";
import { lazy } from "@loadable/component";
import { ServerSuspense } from "@plinknz/tah-website-elements";

interface SurveyQuestionOptions {
    question: SurveyType.Question;
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    error: string;
}

export interface SurveyProps {
    model: SurveyType.SurveyModel;
    onComplete: (survey: SurveyType.SurveyModel) => void;
}
const validateQuestion = (
    _s: SurveyType.SurveyModel,
    options: SurveyQuestionOptions
) => {
    /* eslint-disable no-param-reassign */
    if (options.name === "addressfinder") {
        if (!options.value.address_line_1) {
            options.error = "Address must contain Line 1";
        } else if (!options.value.city) {
            options.error = "Address must contain City";
        } else if (!options.value.country) {
            options.error = "Address must contain Country";
        }
    }
    /* eslint-disable no-param-reassign */
};

// TODO: move to tah-website-elements

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SurveyComponent = lazy<any>(async () =>
    import(`survey-react`).then((module) => ({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        default: module.Survey,
    }))
);

export const Survey = ({ model, onComplete }: SurveyProps) => {
    return (
        <ServerSuspense fallback={<i />}>
            {model && (
                <SurveyComponent
                    model={model}
                    onValidateQuestion={validateQuestion}
                    onComplete={onComplete}
                />
            )}
        </ServerSuspense>
    );
};
