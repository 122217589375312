import * as React from "react";
import { Helmet } from "react-helmet";

const SUFFIX = "Ngāti Kuia";

interface PageTitleProps {
    title: string;
    showSuffix?: boolean;
}

export const PageTitle = ({ title, showSuffix = true }: PageTitleProps) => {
    let formattedTitle = title;

    if (showSuffix) {
        formattedTitle += ` | ${SUFFIX}`;
    }

    return (
        <Helmet>
            <title>{formattedTitle}</title>
        </Helmet>
    );
};
