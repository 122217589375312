import React, { useEffect, useState } from "react";
import { BankRecords } from "../../config/back-to-school-grant/BankBranchRegister-12Jan2022";
import { Dropdown } from "./dropdown";
import { Field } from "./field";

export interface BankAccountType {
    bank: string;
    branch: string;
    name: string;
    account: string;
    suffix: string;
}

interface BankAccountProps {
    onChange?: (value: BankAccountType) => void;
}

export const BankAccount = ({ onChange }: BankAccountProps) => {
    const [bankList, setBankList] = useState<string[]>([]);
    const [bankBranchList, setBankBranchList] = useState<string[][]>([]);
    const [branchList, setBranchList] = useState<string[]>([]);
    const [bank, setBank] = useState<string>("");
    const [branch, setBranch] = useState<string>("");
    const [accountName, setAccountName] = useState<string>("");
    const [accountNumber, setAccountNumber] = useState<string>("");
    const [suffix, setSuffix] = useState<string>("");

    useEffect(() => {
        const newBankList: string[] = ["Select Bank"];
        const newBankBranchList: string[][] = [];
        let lastBankNumber = "";
        let newBranchList = ["Select Branch"];

        BankRecords.forEach((record) => {
            if (lastBankNumber !== record.Bank_Number) {
                newBankList.push(`${record.Bank_Number} `);

                if (lastBankNumber !== "") {
                    newBankBranchList.push(newBranchList);
                    newBranchList = [
                        "Select Branch",
                        `${record.Branch_Number} `,
                    ];
                }

                lastBankNumber = record.Bank_Number;
            } else {
                newBranchList.push(`${record.Branch_Number} `);
            }
        });

        if (newBranchList.length > 1) {
            newBankBranchList.push(newBranchList);
        }

        setBankList(newBankList);
        setBankBranchList(newBankBranchList);
    }, []);

    useEffect(() => {
        onChange({
            bank: bank.substring(0, 2),
            branch: branch.substring(0, 4),
            name: accountName,
            account: accountNumber,
            suffix,
        });
    }, [bank, branch, accountName, accountNumber, suffix]);

    return (
        <div className="form-group">
            <div className="form-row">
                <div className="form-group">
                    <Field
                        id="account-name"
                        data-testid="account-name"
                        name="Account Name"
                        type="text"
                        placeholder="Enter Account Name"
                        onChange={(
                            value: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setAccountName(value.currentTarget.value);
                        }}
                    />
                </div>
            </div>
            <div className="form-group">
                <div
                    className="form-bankaccount"
                    id="form-bankaccount"
                    data-testid="form-bankaccount">
                    <div className="form-group">
                        <Dropdown
                            data-testid="bank"
                            name="bank"
                            options={bankList}
                            selected={bank}
                            onChange={(value: string) => {
                                const bankIndex = bankList.indexOf(value);

                                if (bankIndex === 0) {
                                    setBank("");
                                    setBranchList([]);
                                } else {
                                    setBank(value);
                                    setBranchList(
                                        bankBranchList[bankIndex - 1]
                                    );
                                }
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <Dropdown
                            data-testid="branch"
                            name="branch"
                            options={branchList}
                            selected={branch}
                            onChange={(value: string) => {
                                setBranch(
                                    branchList.indexOf(value) === 0 ? "" : value
                                );
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            data-testid="account-number"
                            className="bank-account-number"
                            type="number"
                            placeholder="Enter 7 Digit Account Number"
                            onChange={(
                                value: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setAccountNumber(value.currentTarget.value);
                            }}
                        />
                    </div>

                    <div className="form-group">
                        <input
                            data-testid="suffix"
                            className="bank-account-suffix"
                            type="number"
                            placeholder="Enter Suffix"
                            onChange={(
                                value: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                // eslint-disable-next-line @typescript-eslint/no-shadow
                                let suffix = value.currentTarget.value;
                                if (suffix.length === 2) {
                                    suffix = `0${suffix}`;
                                }
                                if (suffix.length === 3) {
                                    setSuffix(suffix);
                                } else {
                                    setSuffix("");
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
