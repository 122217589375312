import * as React from "react";

export interface IconProps extends React.HTMLAttributes<SVGElement> {
    name: string;
}

export const Icon = ({ name, ...rest }: IconProps) => {
    return (
        <svg viewBox="0 0 40 40" width="40" height="40" {...rest}>
            <use xlinkHref={`/icons/_icon.svg#${name}`} />
        </svg>
    );
};
